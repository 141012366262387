import React, { Component } from "react";
import {
  Card,
  CardContent,
  CircularProgress,
  createStyles,
  TextField,
  Theme,
  Typography,
  withStyles,
  WithStyles
} from "@material-ui/core";
import Onboarding from "../components/accounts/Onboarding";
import SubmitButton from "../components/accounts/SubmitButton";
import gql from "graphql-tag";
import { Mutation, Query } from "react-apollo";
import { OnboardingAppCredentials } from "../graphql/OnboardingAppCredentials";
import { navigate } from "gatsby";
import { SecretField } from "../components/common/SecretField";
import { TextFieldProps } from "@material-ui/core/TextField";
import PrivateRoute from "../components/auth/PrivateRoute";

const styles = (theme: Theme) =>
  createStyles({
    root: {},
    details: {
      marginTop: theme.spacing(4),
      color: theme.palette.grey[700]
    },
    card: {
      boxShadow: "none",
      width: 500,
      marginLeft: -50
    },
    cardContent: {
      paddingLeft: theme.spacing(6),
      paddingRight: theme.spacing(6)
    },
    label: {
      marginTop: 2,
      transform: `translate(0, ${theme.spacing(4)}px) scale(0.75)`
    },
    inputPadding: {},
    input: {
      marginBottom: theme.spacing(2),
      "&:first-child": {
        marginTop: theme.spacing(1)
      },
      "& $inputPadding": {
        marginTop: 0
      }
    }
  });

interface Props extends WithStyles<typeof styles> {}

const query = gql`
  query OnboardingAppCredentials {
    appById(id: "default") {
      id
      name
      clientId
      clientSecret
    }
  }
`;

const mutation = gql`
  mutation MoveToFirstSteps {
    updateOnboarding(currentStep: "first-app", nextStep: "first-steps") {
      id
      onboarding
    }
  }
`;

export class FirstApp extends Component<Props> {
  render() {
    const { classes } = this.props;
    return (
      <Onboarding
        title="Wasn't that easy?"
        subtitle="Below you will find your API credentials, keep them safe!"
      >
        <Query<OnboardingAppCredentials> query={query}>
          {({ data }) =>
            data && data.appById ? (
              <Card className={classes.card}>
                <CardContent className={classes.cardContent}>
                  <this.Field
                    component={TextField}
                    label="Client ID"
                    value={data.appById.clientId}
                  />
                  {data.appById.clientSecret && (
                    <this.Field
                      component={SecretField}
                      label="Secret"
                      value={data.appById.clientSecret}
                    />
                  )}
                </CardContent>
              </Card>
            ) : (
              <CircularProgress />
            )
          }
        </Query>

        <Typography className={classes.details}>
          Not a developer? Don't worry, click to continue
        </Typography>

        <Mutation mutation={mutation} onCompleted={this.onComplete}>
          {(updateOnboarding, { loading }) => (
            <SubmitButton
              disabled={loading}
              onClick={() => updateOnboarding()}
            />
          )}
        </Mutation>
      </Onboarding>
    );
  }

  private Field = ({
    component: Component,
    value,
    label
  }: {
    component: React.ComponentType<TextFieldProps>;
    value: string;
    label: string;
  }) => {
    const { classes } = this.props;

    return (
      <Component
        margin="normal"
        value={value}
        label={label}
        fullWidth
        className={classes.input}
        InputProps={{
          classes: {
            root: classes.inputPadding
          }
        }}
        InputLabelProps={{
          classes: {
            shrink: classes.label
          }
        }}
      />
    );
  };

  private onComplete = () => {
    navigate("/first-steps");
  };
}

const FirstAppPage = withStyles(styles)(FirstApp);
export default () => <PrivateRoute component={FirstAppPage} />;
