import { IconButton, InputAdornment, TextField } from "@material-ui/core";
import React, { Component } from "react";
import { ButtonProps } from "@material-ui/core/Button";
import { LinkProps } from "@reach/router";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Visibility from "@material-ui/icons/Visibility";
import { TextFieldProps } from "@material-ui/core/TextField";

export type LinkButtonProps = ButtonProps & LinkProps<{}>;

interface Props extends TextFieldProps {
  value: string;
}

interface State {
  showSecret: boolean;
}

export class SecretField extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      showSecret: false
    };
  }

  render() {
    const { value, InputProps, ...rest } = this.props;
    const { showSecret } = this.state;
    return (
      <TextField
        {...rest}
        type={showSecret ? "text" : "password"}
        value={value}
        InputProps={{
          ...InputProps,
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="Toggle password visibility"
                onClick={this.handleClickShowPassword}
                onMouseDown={this.handleMouseDownPassword}
              >
                {showSecret ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          )
        }}
      />
    );
  }

  private handleMouseDownPassword = (event: React.MouseEvent) => {
    event.preventDefault();
  };

  private handleClickShowPassword = () => {
    this.setState({ showSecret: !this.state.showSecret });
  };
}
